import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('deliverable_badge_info_status')
@doc.path('/deliverable_badge_info_statuses/{?id}')
export default class DeliverableBadgeInfoStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_DeliverableBadgeInfoStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Shop_DeliverableBadgeInfoStatusReference;

	public get color() {
		switch (this.reference) {
			case 'waiting':
				return 'info';
			case 'to_print':
				return 'orange';
			case 'printed':
				return 'green';
		}
	}
}