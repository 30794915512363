import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import ShopServiceModel from '@/models/ShopServiceModel';
import model            from '@widesk-core/decorators/model';
import doc              from '@widesk-core/decorators/doc';

@model.urnResource('policy')
@doc.path('/stonecode_file_policies/{?id}')
export default class PolicyModel extends TimestampAble(ShopServiceModel) {
	private declare _tsFilters: {
		'reference': FilePolicyReference;
	};

	private declare _tsSorts: {
		'id': string;
		'reference': string;
		'updatedAt': string;
	};

	@doc.string declare reference: FilePolicyReference;
}
