import ShopServiceModel from '@/models/ShopServiceModel';
import model            from '@widesk-core/decorators/model';
import doc              from '@widesk-core/decorators/doc';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.urnResource('source')
@doc.path('/stonecode_file_sources/{?id}')
export default class SourceModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'entityUrn': string;
	};

	private declare _tsSorts: {
		'id': string;
		'updatedAt': string;
	};

	@doc.string declare entityUrn: Urn;
}
