import EntertainmentModel    from '@models/shop/EntertainmentModel';
import ProductStatusModel    from '@models/shop/ProductStatusModel';
import doc                   from '@widesk-core/decorators/doc';
import model                 from '@widesk-core/decorators/model';
import ShopServiceModel      from '@/models/ShopServiceModel';
import TimestampAble         from '@widesk-core/models/traits/TimestampAble';
import Blamable              from '@/models/traits/Blamable';
import Translatable          from '@widesk-core/models/traits/Translatable';
import AccessTypeModel       from '@models/scaf/AccessTypeModel';
import PagedCollection       from '@widesk-core/models/PagedCollection';
import { RenderNameProps }   from '@widesk-ui/mixins/ApiModelRenderName';
import WithEntertainmentPath from '@/models/mixins/withEntetainmentPath';
import ExpirationTypeModel   from '@models/shop/ExpirationTypeModel';
import ProductTypeModel      from '@models/shop/ProductTypeModel';
import VatModel              from '@models/shop/VatModel';

@model.urnResource('product')
@doc.path('/products/{?id}')
export default class ProductModel extends Blamable(TimestampAble(Translatable(WithEntertainmentPath(ShopServiceModel), {
	'title': true,
	'description': true,
	'badgeInformation': true,
	'termsOfUse': true,
}))) {
	public accessType = new AccessTypeModel();

	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'endDate[after]': string | Date | Dayjs;
		'endDate[before]': string | Date | Dayjs;
		'endDate[strictly_after]': string | Date | Dayjs;
		'endDate[strictly_before]': string | Date | Dayjs;
		'entertainment': id;
		'entertainment.entertainmentUrn': Urn;
		'productStatus': id;
		'productStatus.reference': Shop_ProductStatusReference;
		'productTaxonomies.taxonomy': id;
		'slug': id;
		'startDate[after]': string | Date | Dayjs;
		'startDate[before]': string | Date | Dayjs;
		'startDate[strictly_after]': string | Date | Dayjs;
		'startDate[strictly_before]': string | Date | Dayjs;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'endDate': string;
		'entertainment.label': string;
		'id': string;
		'position': string;
		'startDate': string;
		'translations.title': string;
		'updatedAt': string;
	};

	@doc.number declare bankDelay: number;
	@doc.date declare endDate: Dayjs;
	@doc.model(EntertainmentModel) declare entertainment: EntertainmentModel;
	@doc.model(ExpirationTypeModel) declare expirationType: ExpirationTypeModel;
	@doc.boolean declare manualValidation: boolean;
	@doc.number declare maxQuantity: number;
	@doc.number declare maxQuantityPerOrder: number;
	@doc.number declare position: number;
	@doc.model(ProductStatusModel) declare productStatus: ProductStatusModel;
	@doc.model(ProductTypeModel) declare productType: ProductTypeModel;
	@doc.string declare slug: string;
	@doc.date declare startDate: Dayjs;
	@doc.model(VatModel) declare vat: VatModel;

	public renderName(props?: RenderNameProps<this> & ProductNameProps) {
		return super.renderName(props);
	}

	public async fetchAccessType() {
		const collection = new PagedCollection(AccessTypeModel);
		await collection
			.clear()
			.setItemsPerPage(1)
			.setFilter('sourceUrn', this.urn)
			.list();

		const model = collection.first();

		if (model) this.accessType.set(collection.first()?.attributes);

		this.accessType.setIsLoaded(true);
	}
}