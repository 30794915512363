import FondationsServiceModel from '@/models/FondationsServiceModel';
import {computed}             from 'mobx';
import {jwtDecode}            from 'jwt-decode';
import {Platform}             from '@/proto/proto';

import UserRights = Platform.Service.Fondations.UserRights;

export default class LoginCheckModel extends FondationsServiceModel {
    static get path(): string {
        return '/login_check';
    }

    @computed get token(): string {
        return this.get('token', '');
    }

    @computed get refreshToken(): string {
        return this.get('refresh_token', '');
    }

    @computed get tokenData(): any {
        return jwtDecode(this.token) || {};
    }

    @computed get userRights(): UserRights {
        return UserRights.decode(new TextEncoder().encode(this.tokenData['platform.token.owner']));
    }

    @computed get roleIdentifiers() {
        return this.userRights.roles.map(role => role.urn?.identifier || '');
    }
}
