import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('order_status')
@doc.path('/order_statuses/{?id}')
export default class OrderStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_OrderStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Shop_OrderStatusReference;

	public get color() {
		switch (this.reference) {
			case 'created':
				return 'blue';
			case 'to_pay':
				return 'orange';
			case 'expired':
			case 'canceled':
			case 'refused':
				return 'red';
			case 'paid':
				return '#00A38C';
			default:
				return 'black';
		}
	}

	public static getIdFromReference(reference: Shop_OrderStatusReference) {
		const ids: Partial<Record<Shop_OrderStatusReference, string>> = {
			to_pay: '2',
			expired: '3',
			paid: '5',
			canceled: '6',
			refunded: '7',
		};

		return ids[reference] || '';
	}

	public static getUrnFromReference(reference: Shop_OrderStatusReference) {
		return `jts:shop:order_status:${this.getIdFromReference(reference)}`;
	}
}
