import authStore        from '@widesk-core/stores/authStore';
import axios            from 'axios';
import { message }      from '@widesk-ui/hooks/useMessage';
import { showBlob }     from '@widesk-core/tools/blobTools';
import { downloadBlob } from '@widesk-core/tools/blobTools';

export const getBlob = async (url: string): Promise<Blob | undefined> => {
	try {
		const token = authStore.token;
		const response = await axios.get(
			url,
			{
				headers: {
					'X-Request-Context': 'admin',
					'X-Platform-Authorization': `Bearer ${token}`,
				},
				responseType: 'blob',
			},
		);

		return response.data;
	} catch (error) {
		message.exception(error);
	}
};

export const downloadUrl = async (url: string, name?: string) => {
	const blob = await getBlob(url);
	if (blob) {
		const fileName = name || 'file';

		downloadBlob(blob, fileName);
	}
};

export const previewUrl = async (url: string) => {
	const blob = await getBlob(url);
	if (blob) {
		showBlob(blob);
	}
};
