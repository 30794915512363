import DeliveryStatusModel from '@models/shop/DeliveryStatusModel';
import ShippingMethodModel from '@models/shop/ShippingMethodModel';
import doc                 from '@widesk-core/decorators/doc';
import model               from '@widesk-core/decorators/model';
import ShopServiceModel    from '@/models/ShopServiceModel';
import TimestampAble       from '@widesk-core/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';

@model.urnResource('delivery')
@doc.path('/deliveries/{?id}')
export default class DeliveryModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'deliveryItems.deliverable': id;
		'deliveryItems.deliverable.orderItemUnit': id;
		'deliveryItems.deliverable.orderItemUnit.orderItem': id;
		'deliveryItems.deliverable.orderItemUnit.orderItem.order': id;
		'deliveryItems.deliverable.orderItemUnit.orderItem.order.reference': Shop_OrderReference;
		'deliveryItems.deliverable.owner': id;
		'deliveryItems.deliverable.owner.userUrn': Urn;
		'deliveryStatus': id;
		'number': number;
		'reference': Shop_DeliveryReference;
		'shipments': id;
		'shipments.shipmentTrackingCodes': id;
		'shipments.shipmentTrackingCodes.value': id;
		'shippingMethod': id;
		'shippingMethod.reference': Shop_ShippingMethodReference;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};	

	@doc.model(DeliveryStatusModel) declare deliveryStatus: DeliveryStatusModel;
	@doc.string declare label: string;
	@doc.number declare number: number;
	@doc.string declare reference: Shop_DeliveryReference;
	@doc.model(ShippingMethodModel) declare shippingMethod: ShippingMethodModel;
}