import DeliverableStatusModel from '@models/shop/DeliverableStatusModel';
import OrderItemUnitModel     from '@models/shop/OrderItemUnitModel';
import doc                    from '@widesk-core/decorators/doc';
import model                  from '@widesk-core/decorators/model';
import ShopServiceModel       from '@/models/ShopServiceModel';
import TimestampAble          from '@widesk-core/models/traits/TimestampAble';
import Blamable               from '@/models/traits/Blamable';
import ShippingMethodModel    from '@models/shop/ShippingMethodModel';
import ClientModel            from '@models/shop/ClientModel';
import _omitBy                from 'lodash/omitBy';
import DeliveryBatchModel     from '@models/shop/DeliveryBatchModel';
import DeliveryModel          from '@models/shop/DeliveryModel';
import { message }            from '@widesk-ui/hooks/useMessage';
import ApiCollection          from '@widesk-core/models/ApiCollection';
import WithEntertainmentPath  from '@/models/mixins/withEntetainmentPath';

const customDocModel = doc.model;

@model.urnResource('deliverable')
@doc.path('/deliverables/{?id}')
export default class DeliverableModel extends Blamable(TimestampAble(WithEntertainmentPath(ShopServiceModel))) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'deliverableShipmentAddresses.shipmentAddress': id;
		'deliverableStatus': id;
		'deliverableStatus.reference': Shop_DeliverableStatusReference;
		'orderItemUnit': id;
		'orderItemUnit.orderItem.order': id;
		'orderItemUnit.orderItem.order.client': id;
		'orderItemUnit.orderItem.order.client.userUrn': Urn;
		'orderItemUnit.orderItem.order.network': id;
		'orderItemUnit.orderItem.order.network.reference': Shop_NetworkReference;
		'orderItemUnit.orderItem.product': id;
		'orderItemUnit.orderItem.product.entertainment': id;
		'orderItemUnit.orderItem.product.entertainment.entertainmentUrn': Urn;
		'orderItemUnit.orderItem.product.productTemplate': id;
		'orderItemUnit.orderItem.product.productTemplate.productType': id;
		'orderItemUnit.orderItem.product.productTemplate.productType.reference': Shop_ProductTypeReference;
		'orderItemUnit.orderItem.product.title': string;
		'orderItemUnit.orderItem.productPricingClientGroup.productPricing.product': id;
		'orderItemUnit.orderItem.productPricingClientGroup.productPricing.product.entertainment': id;
		'orderItemUnit.orderItem.productPricingClientGroup.productPricing.product.entertainment.entertainmentUrn': Urn;
		'orderItemUnit.orderItem.productPricingClientGroup.productPricing.product.productTemplate': id;
		'orderItemUnit.orderItem.productPricingClientGroup.productPricing.product.productTemplate.productType': id;
		'orderItemUnit.orderItem.productPricingClientGroup.productPricing.product.productTemplate.productType.reference': Shop_ProductTypeReference;
		'owner': id;
		'owner.userUrn': Urn;
		'shippingMethod': id;
		'shippingMethod.reference': Shop_ShippingMethodReference;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};	

	@doc.model(DeliverableStatusModel) declare deliverableStatus: DeliverableStatusModel;
	@doc.model(OrderItemUnitModel) declare orderItemUnit: OrderItemUnitModel;
	@doc.model(ShippingMethodModel) declare shippingMethod: ShippingMethodModel;

	@customDocModel(ClientModel) declare owner: ClientModel;

	public static async print(filters: ModelFilters<DeliverableModel>) {
		const shippingMethodCollection = new ApiCollection(ShippingMethodModel);
		await shippingMethodCollection.list();

		const shippingMethod = shippingMethodCollection.find(m => m.reference === 'print');

		const deliverableFilters = _omitBy(filters, (_, key) => {
			return ['step', 'page'].includes(key) || key.startsWith('order[');
		});

		const batch = new DeliveryBatchModel();
		await batch.patch({ label: 'Impression', shippingMethod: shippingMethod?.urn, deliverableFilters });

		const delivery = new DeliveryModel(batch.attributes);

		message.success(`La demande d'impression a bien été enregistrée`);

		return delivery;
	}

	public async regenerate() {
		return this.save({ path: '/deliverables/_regenerate', method: 'post', patchAttributes: {
			deliverable: this.urn,
			shippingMethod: this.shippingMethod.urn,
		}});
	}
}