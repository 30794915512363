import doc                          from '@widesk-core/decorators/doc';
import model                        from '@widesk-core/decorators/model';
import ShopServiceModel             from '@/models/ShopServiceModel';
import TimestampAble                from '@widesk-core/models/traits/TimestampAble';
import Blamable                     from '@/models/traits/Blamable';
import EntertainmentStatusModel     from '@models/shop/EntertainmentStatusModel';
import FondationsEntertainmentModel from '@models/fondations/EntertainmentModel';
import Translatable                 from '@widesk-core/models/traits/Translatable';

@model.urnResource('entertainment')
@doc.path('/entertainments/{?id}')
export default class ShopEntertainmentModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'access': true,
}))) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'entertainmentStatus': id;
		'entertainmentStatus.reference': Shop_EntertainmentStatusReference;
		'entertainmentUrn': Urn;
		'label': string;
		'slug': id;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'position': string;
		'updatedAt': string;
	};

	@doc.model(EntertainmentStatusModel) declare entertainmentStatus: EntertainmentStatusModel;
	@doc.modelUrn(FondationsEntertainmentModel) declare entertainment: FondationsEntertainmentModel;
	@doc.string declare label: string;
	@doc.number declare position: number;
	@doc.string declare slug: string;
	@doc.string declare timezone: string;
}
