import doc                      from '@widesk-core/decorators/doc';
import model                    from '@widesk-core/decorators/model';
import ShopServiceModel         from '@/models/ShopServiceModel';
import TimestampAble            from '@widesk-core/models/traits/TimestampAble';
import Blamable                 from '@/models/traits/Blamable';
import Translatable             from '@widesk-core/models/traits/Translatable';
import MessageTemplateTypeModel from '@models/shop/MessageTemplateTypeModel';

@model.urnResource('message_template')
@doc.path('/message_templates/{?id}')
export default class MessageTemplateModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'content': true,
}))) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'messageTemplateType': id;
		'messageTemplateType.reference': Shop_MessageTemplateTypeReference;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'updatedAt': string;
	};	

	@doc.string declare label: string;
	@doc.model(MessageTemplateTypeModel) declare messageTemplateType: MessageTemplateTypeModel;
}
