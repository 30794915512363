import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('entertainment_status')
@doc.path('/entertainment_statuses/{?id}')
export default class EntertainmentStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_EntertainmentStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Shop_EntertainmentStatusReference;

	public get color() {
		switch (this.reference) {
			case 'disabled':
				return 'red';
			case 'enabled':
				return 'green';
		}
	}
}