import doc               from '@widesk-core/decorators/doc';
import model             from '@widesk-core/decorators/model';
import ShopServiceModel  from '@/models/ShopServiceModel';
import TimestampAble     from '@widesk-core/models/traits/TimestampAble';
import Blamable          from '@/models/traits/Blamable';
import { modelUrn }      from '@widesk-core/decorators/modelUrn';
import OrderAddressModel from '@models/shop/OrderAddressModel';

@model.urnResource('shipment_address')
@doc.path('/shipment_addresses/{?id}')
export default class ShipmentAddressModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'deliverableShipmentAddresses.deliverable.orderItemUnit.orderItem.order.reference': Shop_OrderReference;
	};

	private declare _tsSorts: {
		'id': string;
	};	

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.string declare city: string;
	@doc.string declare companyName: string;
	@doc.string declare country: string;
	@doc.string declare firstname: string;
	@doc.string declare lastname: string;
	@doc.string declare zipCode: string;

	@modelUrn(OrderAddressModel) declare source: OrderAddressModel;

	public get fullName() {
		return `${this.firstname} ${this.lastname}`;
	}
}