import ShippingMethodModel from '@models/shop/ShippingMethodModel';
import doc                 from '@widesk-core/decorators/doc';
import ShopServiceModel    from '@/models/ShopServiceModel';
import DeliverableModel    from '@models/shop/DeliverableModel';

@doc.path('/deliveries/_batch')
export default class DeliveryBatchModel extends ShopServiceModel {
	private declare _tsFilters: ModelFilters<DeliverableModel>;

	private declare _tsSorts: ModelSorts<DeliverableModel>;

	@doc.string declare label: string;
	@doc.model(ShippingMethodModel) declare shippingMethod: ShippingMethodModel;
	@doc.string declare trackingCode: string;
}
