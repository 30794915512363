import FondationsServiceModel from '@/models/FondationsServiceModel';
import { computed }           from 'mobx';
import model                  from '@widesk-core/decorators/model';
import { RenderNameProps }    from '@widesk-ui/mixins/ApiModelRenderName';

@model.urnResource('user')
export default class UserModel extends FondationsServiceModel {
	private declare _tsFilters: {
		'enabled': boolean;
		'email': string;
		'emailVerified': boolean;
		'userUserGroups.userGroup': id;
		'userUserGroups.userGroup.reference': Fondations_UserGroupReference;
	};
	private declare _tsSorts: {
		'emailVerified': string;
		'enabled': string;
		'firstName': string;
		'gender': string;
		'lastName': string;
	};

	static get path(): string {
		return '/users/{?id}';
	}

	public get firstName() {
		return this.get('firstName', '');
	}

	public get lastName() {
		return this.get('lastName', '');
	}

	public get email() {
		return this.get('email', '');
	}

	public get gender() {
		return this.get('gender', 0);
	}

	public get enabled() {
		return this.get('enabled', false);
	}

	public get emailVerified() {
		return this.get('enabled', false);
	}

	@computed get fullName() {
		return this.firstName + ' ' + this.lastName;
	}

	@computed get profilePictureUrl() {
		return this.get('userMediaProfilePicture.objectUrl');
	}

	public renderName(props?: RenderNameProps<this> & UserNameProps) {
		return super.renderName(props);
	}
}
