import ClientModel                from '@models/shop/ClientModel';
import CurrencyModel              from '@models/shop/CurrencyModel';
import OrderStatusModel           from '@models/shop/OrderStatusModel';
import PaymentMethodModel         from '@models/shop/PaymentMethodModel';
import SyncStatusModel            from '@models/shop/SyncStatusModel';
import doc                        from '@widesk-core/decorators/doc';
import model                      from '@widesk-core/decorators/model';
import ShopServiceModel           from '@/models/ShopServiceModel';
import TimestampAble              from '@widesk-core/models/traits/TimestampAble';
import Blamable                   from '@/models/traits/Blamable';
import OrderAddressModel          from '@models/shop/OrderAddressModel';
import OrderValidationStatusModel from '@models/shop/OrderValidationStatusModel';
import NetworkModel               from '@models/shop/NetworkModel';
import WithEntertainmentPath      from '@/models/mixins/withEntetainmentPath';

@model.urnResource('order')
@doc.path('/orders/{?id}')
export default class OrderModel extends Blamable(TimestampAble(WithEntertainmentPath(ShopServiceModel))) {
	private declare _tsFilters: {
		'client': id;
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'expiresAt[after]': string | Date | Dayjs;
		'expiresAt[before]': string | Date | Dayjs;
		'expiresAt[strictly_after]': string | Date | Dayjs;
		'expiresAt[strictly_before]': string | Date | Dayjs;
		'locale': id;
		'network': id;
		'network.reference': Shop_NetworkReference;
		'number': number;
		'orderItems.orderItemUnits': id;
		'orderItems.productPricingClientGroup.productPricing.product': id;
		'orderItems.productPricingClientGroup.productPricing.product.entertainment': id;
		'orderStatus': id;
		'orderStatus.reference': Shop_OrderStatusReference;
		'orderValidationStatus': id;
		'orderValidationStatus.reference': Shop_OrderValidationStatusReference;
		'paymentMethod': id;
		'paymentMethod.reference': Shop_PaymentMethodReference;
		'reference': Shop_OrderReference;
		'sourceUrn': Urn;
		'transactions.dueDate[after]': string | Date | Dayjs;
		'transactions.dueDate[before]': string | Date | Dayjs;
		'transactions.dueDate[strictly_after]': string | Date | Dayjs;
		'transactions.dueDate[strictly_before]': string | Date | Dayjs;
		'transactions.transactionStatus': id;
		'transactions.transactionStatus.reference': Shop_TransactionStatusReference;
		'transactions.transactionType': id;
		'transactions.transactionType.reference': Shop_TransactionTypeReference;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};	

	@doc.number declare amountExclTax: number;
	@doc.number declare amountTaxIncl: number;
	@doc.model(ClientModel) declare client: ClientModel;
	@doc.model(CurrencyModel) declare currency: CurrencyModel;
	@doc.date declare expiresAt?: Dayjs;
	@doc.string declare locale: string;
	@doc.model(NetworkModel) declare network: NetworkModel;
	@doc.number declare number: number;
	@doc.model(OrderStatusModel) declare orderStatus: OrderStatusModel;
	@doc.model(OrderValidationStatusModel) declare orderValidationStatus: OrderValidationStatusModel;
	@doc.model(PaymentMethodModel) declare paymentMethod: PaymentMethodModel;
	@doc.string declare reference: Shop_OrderReference;
	@doc.model(SyncStatusModel) declare syncStatus: SyncStatusModel;

	public get invoicingAddress(): OrderAddressModel {
		return this.get('invoicingAddress');
	}
}

// TODO DOC - Ajouter la propriété "source"