import EntertainmentModel from '@models/shop/EntertainmentModel';
import doc                from '@widesk-core/decorators/doc';
import model              from '@widesk-core/decorators/model';
import ShopServiceModel   from '@/models/ShopServiceModel';
import TimestampAble      from '@widesk-core/models/traits/TimestampAble';
import Blamable           from '@/models/traits/Blamable';

@model.urnResource('schedule_group')
@doc.path('/schedule_groups/{?id}')
export default class ScheduleGroupModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'entertainment': id;
		'entertainment.entertainmentUrn': Urn;
		'scafPeriodUrn': Urn;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'updatedAt': string;
	};	

	@doc.model(EntertainmentModel) declare entertainment: EntertainmentModel;
	@doc.string declare label: string;

	// TODO DOC - Ajouter la propriété "scafPeriod"
}
