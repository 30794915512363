import ProductPricingModel from '@models/shop/ProductPricingModel';
import doc                 from '@widesk-core/decorators/doc';
import model               from '@widesk-core/decorators/model';
import ShopServiceModel    from '@/models/ShopServiceModel';
import TimestampAble       from '@widesk-core/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';

@model.urnResource('product_pricing_stock_change')
@doc.path('/product_pricing_stock_changes/{?id}')
export default class ProductPricingStockChangeModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'productPricing': id;
		'quantity[between]': string;
		'quantity[gt]': string;
		'quantity[gte]': string;
		'quantity[lt]': string;
		'quantity[lte]': string;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'quantity': string;
		'updatedAt': string;
	};

	@doc.model(ProductPricingModel) declare productPricing: ProductPricingModel;
	@doc.number declare quantity: number;
}
