import FondationsEntertainmentModel from '@models/fondations/EntertainmentModel';
import doc                          from '@widesk-core/decorators/doc';
import model                        from '@widesk-core/decorators/model';
import TimestampAble                from '@widesk-core/models/traits/TimestampAble';
import Blamable                     from '@/models/traits/Blamable';
import CodeServiceModel             from '@/models/CodeServiceModel';

@model.urnResource('code_entertainment')
@doc.path('/code_entertainments/{?id}')
export default class CodeEntertainmentModel extends Blamable(TimestampAble(CodeServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'entertainmentUrn': Urn;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.modelUrn(FondationsEntertainmentModel) declare entertainment: FondationsEntertainmentModel;
}
