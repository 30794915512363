import doc                    from '@widesk-core/decorators/doc';
import model                  from '@widesk-core/decorators/model';
import TimestampAble          from '@widesk-core/models/traits/TimestampAble';
import Blamable               from '@/models/traits/Blamable';
import FondationsServiceModel from '@/models/FondationsServiceModel';
import Translatable           from '@widesk-core/models/traits/Translatable';

@model.cacheDuration()
@model.urnResource('entertainment')
@doc.path('/entertainments/{?id}')
export default class FondationsEntertainmentModel extends Blamable(TimestampAble(Translatable(FondationsServiceModel, {
	'title': true,
}))) {
	private declare _tsSorts: {
		'dateStart': string;
		'dateEnd': string;
		'edition': string;
	};
}
