import FileModel                    from '@/models/services/file/FileModel';
import EntertainmentModel           from '@/models/services/shop/EntertainmentModel';
import UserModel                    from '@models/fondations/UserModel';
import ApiCollection                from '@widesk-core/models/ApiCollection';
import { action, computed }         from 'mobx';
import { observable }               from 'mobx';
import { makeObservable }           from 'mobx';

class AppStore {
	public readonly user = new UserModel();

	public entertainmentCollection = new ApiCollection(EntertainmentModel);

	public entertainmentFileLogoCollection = new ApiCollection(FileModel);

	@observable
	private _entertainment: EntertainmentModel | null | 'all' = null;

	constructor() {
		makeObservable(this);
	}

	public async load() {
		this.entertainmentCollection.setSort('createdAt', 'desc');
		await this.entertainmentCollection.list();

		this.entertainmentFileLogoCollection.setRequiredFilter('typeSource.type.reference', 'entertainment_logo_backoffice');
		this.entertainmentFileLogoCollection.setRequiredFilter('typeSource.source.entityUrn', this.entertainmentCollection.urns);
		this.entertainmentFileLogoCollection.list();
	}

	@action
	public async fetchEntertainment(id: id) {
		this._entertainment = new EntertainmentModel({ id: id });
		await this._entertainment.fetch();
	}

	@action
	public resetEntertainment() {
		this._entertainment = null;
	}

	@action
	public setEntertainmentToAll() {
		this._entertainment = 'all';
	}

	@action
	async changeEntertainment(id?: id) {
		if (id === 'all') {
			return this.setEntertainmentToAll();
		}
		if (id) {
			return await this.fetchEntertainment(id);
		}
		return this.resetEntertainment();
	}

	public get entertainment() {
		return this._entertainment;
	}

	@computed
	public get entertainmentUrn(): Urn | undefined {
		return this._entertainment instanceof EntertainmentModel ? this._entertainment.urn : undefined;
	}

	public clear() {
		this.user.clear();
		this.resetEntertainment();
	}
}

export default new AppStore();
