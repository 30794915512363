import DeliveryModel        from '@models/shop/DeliveryModel';
import ShipmentAddressModel from '@models/shop/ShipmentAddressModel';
import ShipmentStatusModel  from '@models/shop/ShipmentStatusModel';
import doc                  from '@widesk-core/decorators/doc';
import model                from '@widesk-core/decorators/model';
import ShopServiceModel     from '@/models/ShopServiceModel';
import TimestampAble        from '@widesk-core/models/traits/TimestampAble';
import Blamable             from '@/models/traits/Blamable';

@model.urnResource('shipment')
@doc.path('/shipments/{?id}')
export default class ShipmentModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'delivery': id;
		'delivery.deliveryItems.deliverable': id;
		'delivery.deliveryItems.deliverable.orderItemUnit.orderItem.order': id;
		'delivery.deliveryItems.deliverable.orderItemUnit.orderItem.order.reference': Shop_OrderReference;
		'delivery.deliveryItems.deliverable.owner': id;
		'delivery.deliveryItems.deliverable.owner.userUrn': Urn;
		'shipmentAddress': id;
		'shipmentStatus': id;
		'shipmentStatus.reference': Shop_ShipmentStatusReference;
		'shipmentTrackingCodes': id;
		'shipmentTrackingCodes.value': id;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};	

	@doc.model(DeliveryModel) declare delivery: DeliveryModel;
	@doc.model(ShipmentAddressModel) declare shipmentAddress: ShipmentAddressModel;
	@doc.model(ShipmentStatusModel) declare shipmentStatus: ShipmentStatusModel;

	public get title() {
		return `EXP#${this.id}`;
	}
}