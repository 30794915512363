import DeliverableModel                from '@models/shop/DeliverableModel';
import DeliverableBadgeInfoStatusModel from '@models/shop/DeliverableBadgeInfoStatusModel';
import doc                             from '@widesk-core/decorators/doc';
import model                           from '@widesk-core/decorators/model';
import ShopServiceModel                from '@/models/ShopServiceModel';
import TimestampAble                   from '@widesk-core/models/traits/TimestampAble';
import Blamable                        from '@/models/traits/Blamable';

@model.urnResource('deliverable_badge_info')
@doc.path('/deliverable_badge_infos/{?id}')
export default class DeliverableBadgeInfoModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'deliverable': id;
		'deliverableBadgeInfoStatus': id;
		'number': number;
		'number[between]': string;
		'number[gt]': string;
		'number[gte]': string;
		'number[lt]': string;
		'number[lte]': string;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'number': string;
		'updatedAt': string;
	};

	@doc.model(DeliverableModel) declare deliverable: DeliverableModel;
	@doc.model(DeliverableBadgeInfoStatusModel) declare deliverableBadgeInfoStatus: DeliverableBadgeInfoStatusModel;
	@doc.string declare lockKey: string;
	@doc.number declare number: number;
}
