import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.cacheDuration()
@model.urnResource('shipping_method')
@doc.path('/shipping_methods/{?id}')

export default class ShippingMethodModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string;
		'createdAt[before]': string;
		'createdAt[strictly_after]': string;
		'createdAt[strictly_before]': string;
		'label': string;
		'reference': Shop_ShippingMethodReference;
		'updatedAt[after]': string | Date;
		'updatedAt[before]': string | Date;
		'updatedAt[strictly_after]': string | Date;
		'updatedAt[strictly_before]': string | Date;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
		'reference': string;
		'updatedAt': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Shop_ShippingMethodReference;
}
