import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('cart_status')
@doc.path('/cart_statuses/{?id}')
export default class CartStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_CartStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Shop_CartStatusReference;

	public get color() {
		switch (this.reference) {
			case 'completed':
				return 'gold';
			case 'to_complete':
				return 'orange';
			case 'created':
				return 'blue';
			case 'expired':
				return 'red';
			case 'ordering':
				return 'lime';
			case 'ordered':
				return 'green';
		}
	}
}