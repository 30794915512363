import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';
import Translatable     from '@widesk-core/models/traits/Translatable';

@model.urnResource('tag')
@doc.path('/tags/{?id}')
export default class TagModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'label': true,
}))) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'translations.label': string;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'translations.label': string;
		'updatedAt': string;
	};

	@doc.string declare color: string;
}
