import ViewPrimary from '@widesk-ui/components/ViewPrimary';
import Icon        from '@widesk-ui/components/Icon';
import Popover     from '@widesk-ui/components/Popover';
import Tooltip     from '@widesk-ui/components/Tooltip';
import useTheme    from '@widesk-ui/hooks/useTheme';
import View        from '@widesk-ui/components/View';

export default function SiderSelectProject() {
	const theme = useTheme();

	return (
		<Tooltip title="Changer d'application" placement="right">
			<Popover content={(
				<View row gap={theme.marginXS}>
					{window.APP_CONFIG.services
						.filter(service => service.public)
						.map(service => {
							return (
								<a key={service.name} href={service.url} target="_blank">
									<View
										width={100}
										height={100}
										bg={service.color}
										center
										color="white"
										gap={4}
										rel
									>
										<Icon svg={service.icon} size={30} />

										{service.name}

										<View abs widthF heightF pointer hover={{
											bg: '#FFFFFF20',
											style: { border: `2px solid ${service.color}` },
										}} />
									</View>
								</a>
							);
						})}
				</View>
			)} trigger="click">
				<div>
					<ViewPrimary pointer height={64} width={49} center hover={{ bg: theme.colorPrimaryBgHover }}>
						<Icon name="appmenu" size={24} />
					</ViewPrimary>
				</div>
			</Popover>
		</Tooltip>
	);
}
