import OrderModel       from '@models/shop/OrderModel';
import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';

@model.urnResource('order_address')
@doc.path('/order_addresses/{?id}')
export default class OrderAddressModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'order': id;
	};

	private declare _tsSorts: {
		'': string;
		'id': string;
	};

	@doc.string declare address: string;
	@doc.string declare addressBis: string;
	@doc.string declare city: string;
	@doc.string declare companyName: string;
	@doc.string declare country: string;
	@doc.string declare firstname: string;
	@doc.string declare lastname: string;
	@doc.model(OrderModel) declare order: OrderModel;
	@doc.string declare zipCode: string;
}
