import { ModelClass } from '@mathquis/modelx/lib/types/collection';
import ApiModel       from '@widesk-core/models/ApiModel';
import { getLocale }  from '@widesk-core/locale';
import _get           from 'lodash/get';

export default function Translatable<
	T extends ModelClass<ApiModel>,
	Props extends Record<string, true>,
>(Base: T, properties: Props) {
	return class Translatable extends Base {
		public translation(name: keyof Props, lang: LanguageKey = getLocale()) {
			return this.get(`translations.${lang}.${name as string}`);
		}

		public get translations() {
			return this.get(`translations`);
		}

		public get properties() {
			return properties;
		}

		public get propertyArr() {
			return Object.keys(properties);
		}

		public async fetchAllTranslations() {
			if (this.id && Object.keys(this.translations || {}).length <= 1) {
				await this.fetch({ translations: '*' });
			}
		}

		// Dans l'ordre : cherche un label dans le model, puis les propriétés {properties} dans les translations,
		// ensuite les autres propriétés du model (ApiModel.modelLabel())
		public get modelLabel(): string {
			const priorityModelProperties = ['label'];
			const modelProperty = priorityModelProperties.find(v => _get(this, v, '') || _get(this.attributes, v, ''));
			if (modelProperty) {
				return _get(this, modelProperty, '') || _get(this.attributes, modelProperty, '');
			}

			const translationProperties = ['label', 'title', 'name'];
			if (this.translations && Object.keys(this.translations).length) {
				const translationProperty = translationProperties.find(p => this.translation(p));
				if (translationProperty) {
					return this.translation(translationProperty);
				}
			}

			return super.modelLabel;
		}

		constructor(...args: any[]) {
			super(...args);
		}
	};
}
