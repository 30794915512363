import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('order_validation_status')
@doc.path('/order_validation_statuses/{?id}')
export default class OrderValidationStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_OrderValidationStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Shop_OrderValidationStatusReference;

	public get color() {
		switch (this.reference) {
			case 'to_validate':
			case 'created':
				return 'blue';
			case 'to_correct':
				return 'orange';
			case 'canceled':
				return 'red';
			case 'validated':
				return 'green';
		}
	}

	public static getIdFromReference(reference: Shop_OrderValidationStatusReference) {
		const ids: Partial<Record<Shop_OrderValidationStatusReference, string>> = {
			to_validate: '1',
			to_correct: '2',
		};

		return ids[reference] || '';
	}

	public static getUrnFromReference(reference: Shop_OrderValidationStatusReference) {
		return `jts:shop:order_validation_status:${this.getIdFromReference(reference)}`;
	}
}