import axios                 from 'axios';
import React                 from 'react';
import FileModel             from '@models/file/FileModel';
import Image                 from '@widesk-ui/components/Image';
import { ImageProps }        from '@widesk-ui/components/Image';
import { blobToBase64Async } from '@widesk-core/tools/blobTools';
import { message }           from '@widesk-ui/hooks/useMessage';
import authStore             from '@widesk-core/stores/authStore';

const cacheB64: Record<string, string | ArrayBuffer | null> = {};

export type FileImageProps = Omit<ImageProps, 'src'> & { file?: FileModel; };

const fetchBase64ForFile = async (file: FileModel) => {
	const src = file.downloadPath;

	if (cacheB64[src]) {
		return cacheB64[src];
	}

	const response = await axios.get(src, {
		headers: {
			'X-Request-Context': 'admin',
			'X-Platform-Authorization': `Bearer ${authStore.token}`,
		},
		responseType: 'blob',
	});

	const b64 = await blobToBase64Async(response.data);

	cacheB64[src] = b64;

	return b64;
};

const FileImage = (props: FileImageProps) => {
	const file = props.file || new FileModel();

	const [loading, setLoading] = React.useState(!!file.id && !file.isLoaded);

	const [base64, setBase64] = React.useState<unknown>();

	const src = file.downloadPath;

	React.useEffect(() => {
		(async () => {
			if (src) {
				try {
					setLoading(true);
					setBase64(await fetchBase64ForFile(file));
				} catch (error) {
					message.exception(error);
				} finally {
					setLoading(false);
				}
			}
		})();
	}, [src]);

	return <Image loading={loading} src={base64?.toString()}{...props} />;
};

FileImage.fetchBase64ForFile = fetchBase64ForFile;

export default FileImage;
