import Blamable         from '@/models/traits/Blamable';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import TypeSourceModel  from '@models/file/TypeSourceModel';
import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import MimeTypeModel    from './MimeTypeModel';
import { downloadUrl }  from '@/helpers/DownloadHelper';
import { previewUrl }   from '@/helpers/DownloadHelper';
import { getBlob }      from '@/helpers/DownloadHelper';
import FileStatusModel  from '@models/file/FileStatusModel';
import { message }      from '@widesk-ui/hooks/useMessage';

@model.urnResource('file')
@doc.path('/stonecode_file_files/{?id}')
export default class FileModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'fileStatus': id;
		'fileStatus.reference': FileFileStatusReference;
		'typeSource': id;
		'typeSource.source': id;
		'typeSource.source.entityUrn': Urn;
		'typeSource.type': id;
		'typeSource.type.reference': FileTypeReference;
		'typeSource.type.service': id;
		'typeSource.type.service.serviceUrn': Urn;
	};

	private declare _tsSorts: {
		'id': string;
		'updatedAt': string;
	};

	@doc.date declare date: Dayjs;
	@doc.string declare description: string;
	@doc.string declare file: string;
	@doc.string declare label: string;
	@doc.string declare name: string;
	@doc.number declare size: number;
	@doc.model(TypeSourceModel) declare typeSource: TypeSourceModel;
	@doc.model(MimeTypeModel) declare mimeType: MimeTypeModel;
	@doc.model(FileStatusModel) declare fileStatus: FileStatusModel;

	public async getFile(name?: string) {
		const blob = await getBlob(this.downloadPath);

		if (blob) {
			return new File([blob], name || this.name);
		}
	}

	public async download(name?: string) {
		await downloadUrl(this.downloadPath, name || this.name);
	}

	public async preview() {
		try {
			await previewUrl(this.downloadPath);
		} catch (error) {
			message.error((error as Error).message);
		}
	}

	public get downloadPath() {
		return (this.isLoaded && !!this.id) ? `${this.connector['options'].baseURL}${this.path}/_download` : '';
	}
}
