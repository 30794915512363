import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('export_status')
@doc.path('/export_statuses/{?id}')
export default class ExportStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_ExportStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Shop_ExportStatusReference;
}