import CodeEntertainmentModel from '@models/code/CodeEntertainmentModel';
import doc                    from '@widesk-core/decorators/doc';
import model                  from '@widesk-core/decorators/model';
import TimestampAble          from '@widesk-core/models/traits/TimestampAble';
import Blamable               from '@/models/traits/Blamable';
import ProductModel           from '@models/shop/ProductModel';
import CodeServiceModel       from '@/models/CodeServiceModel';

@model.cacheDuration()
@model.urnResource('code_type')
@doc.path('/code_types/{?id}')
export default class CodeTypeModel extends Blamable(TimestampAble(CodeServiceModel)) {
	private declare _tsFilters: {
		'codeEntertainment': id;
		'codeEntertainment.entertainmentUrn': Urn;
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'sourceUrn': Urn;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'translations.title': string;
		'updatedAt': string;
	};

	@doc.model(CodeEntertainmentModel) declare codeEntertainment: CodeEntertainmentModel;
	@doc.modelUrn(ProductModel) declare source: ProductModel;
}
