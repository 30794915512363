import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';
import model            from '@widesk-core/decorators/model';
import doc              from '@widesk-core/decorators/doc';
import SourceModel      from '@models/file/SourceModel';
import TypeModel        from '@models/file/TypeModel';

@model.urnResource('type_source')
@doc.path('/stonecode_file_type_sources/{?id}')
export default class TypeSourceModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'locked': '0' | '1';
		'source': id;
		'source.entityUrn': id;
		'type': id;
		'type.deprecated': '0' | '1';
		'type.reference': FileTypeReference;
		'type.service': id;
		'type.service.serviceUrn': string;
		'type.system': '0' | '1';
	};

	private declare _tsSorts: {
		'id': string;
		'updatedAt': string;
	};

	@doc.boolean declare locked: boolean;
	@doc.model(SourceModel) declare source: SourceModel;
	@doc.model(TypeModel) declare type: TypeModel;
}
