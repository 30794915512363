import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';
import Blamable         from '@/models/traits/Blamable';
import CodeServiceModel from '@/models/CodeServiceModel';

@model.urnResource('code_kind')
@doc.path('/code_kinds/{?id}')
export default class CodeKindModel extends Blamable(TimestampAble(CodeServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'label': string;
		'reference': Code_CodeKindReference;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
		'reference': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Code_CodeKindReference;
}
