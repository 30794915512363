import doc                 from '@widesk-core/decorators/doc';
import model               from '@widesk-core/decorators/model';
import ShopServiceModel    from '@/models/ShopServiceModel';
import TimestampAble       from '@widesk-core/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';
import Translatable        from '@widesk-core/models/traits/Translatable';
import { RenderNameProps } from '@widesk-ui/mixins/ApiModelRenderName';

@model.urnResource('partnership')
@doc.path('/partnerships/{?id}')
export default class PartnershipModel extends Blamable(TimestampAble(Translatable(ShopServiceModel, {
	'title': true,
	'link': true,
}))) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'endDate[after]': string | Date | Dayjs;
		'endDate[before]': string | Date | Dayjs;
		'endDate[strictly_after]': string | Date | Dayjs;
		'endDate[strictly_before]': string | Date | Dayjs;
		'startDate[after]': string | Date | Dayjs;
		'startDate[before]': string | Date | Dayjs;
		'startDate[strictly_after]': string | Date | Dayjs;
		'startDate[strictly_before]': string | Date | Dayjs;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'endDate': string;
		'id': string;
		'startDate': string;
		'translations.title': string;
		'updatedAt': string;
	};

	@doc.date declare endDate: Dayjs;
	@doc.string declare label: string;
	@doc.date declare startDate: Dayjs;

	public renderName(props?: RenderNameProps<this> & PartnerNameProps) {
		return super.renderName(props);
	}
}
