import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('export_type')
@doc.path('/export_types/{?id}')
export default class ExportTypeModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_ExportTypeReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};

	@doc.string declare description: string;
	@doc.string declare label: string;
	@doc.string declare reference: Shop_ExportTypeReference;
}