import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('deliverable_status')
@doc.path('/deliverable_statuses/{?id}')
export default class DeliverableStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_DeliverableStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Shop_DeliverableStatusReference;

	public get color() {
		switch (this.reference) {
			case 'used':
				return 'lime';
			case 'to_prepare':
				return 'blue';
			case 'prepared':
				return 'cyan';
			case 'canceled':
				return 'red';
			case 'delivered':
				return 'green';
			case 'being_delivered':
				return 'purple';
			case 'to_deliver':
				return 'orange';
		}
	}
}