import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';
import TimestampAble    from '@widesk-core/models/traits/TimestampAble';

@model.cacheDuration()
@model.urnResource('file')
@doc.path('/stonecode_file_file_statuses/{?id}')
export default class FileStatusModel extends TimestampAble(ShopServiceModel) {
	private declare _tsFilters: unknown;

	private declare _tsSorts: {
		'id': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: FileFileStatusReference;

	public get color() {
		switch (this.reference) {
			case 'available':
				return 'green';
			case 'to_delete':
				return 'red';
			case 'generable':
				return 'blue';
		}
	}
}