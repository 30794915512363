import OrderItemModel      from '@models/shop/OrderItemModel';
import ShippingMethodModel from '@models/shop/ShippingMethodModel';
import doc                 from '@widesk-core/decorators/doc';
import model               from '@widesk-core/decorators/model';
import ShopServiceModel    from '@/models/ShopServiceModel';
import TimestampAble       from '@widesk-core/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';

@model.urnResource('order_item_unit')
@doc.path('/order_item_units/{?id}')
export default class OrderItemUnitModel extends Blamable(TimestampAble(ShopServiceModel)) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'orderItem': id;
		'orderItem.order': id;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.model(OrderItemModel) declare orderItem: OrderItemModel;
	@doc.model(ShippingMethodModel) declare shippingMethod: ShippingMethodModel;

}
